/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  withRouter,
  useLocation,
} from "react-router-dom";
import { LandingContainer } from "./pages/landing-container";
import { Login } from "./pages/login";

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}

const ScrollToTop = withRouter(_ScrollToTop);

const AppRouter = () => {
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user")) || {};
    console.log({ user });
    if (!user?.authToken && window.location.pathname !== "/login") {
      window.location.href = "/login";
    }
  }, []);

  return (
    <Router>
      <ScrollToTop>
        <Route path="/login" component={Login} exact />
        {window.location.pathname === "/login" ? null : (
          <Route path="/" component={LandingContainer} />
        )}
      </ScrollToTop>
    </Router>
  );
};

export default AppRouter;
