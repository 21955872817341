import React, { useState } from "react";
import "./styles/table.scss";
import AppointmentSvg from "../assets/svgs/appointment.svg";
import UsersSvg from "../assets/svgs/users.svg";
import SearchEmpty from "../assets/svgs/searchempty.svg";
import SearchUserEmpty from "../assets/svgs/searchuserempty.svg";
import { SwipeableDrawer } from "@mui/material";
import { EventDetails } from "./event-details";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import {
  formatNumbering,
  SHOULD_SHOW_APPROVE_ACTION,
  SHOULD_SHOW_BLOCK_ACTION,
  SHOULD_SHOW_REJECT_ACTION,
} from "../utils/constants";

const EmptyText = ({ type, tableBig, searchTerm }) => {
  let text = (function (type) {
    switch (type) {
      case "users":
        return "No users have been onboarded";
      case "events":
        return "There are currently no events 🗓";
      default:
        return "There is no data at the moment";
    }
  })(type);

  return searchTerm ? (
    <div className="big-table-empty-display">
      <img
        src={type === "users" ? SearchUserEmpty : SearchEmpty}
        className="img-fluid"
        alt="appointment"
      />
      <h4>
        Sorry we couldn’t find any match for <span> “{searchTerm}”</span>
      </h4>
      <p>Please check spelling or try another word</p>
    </div>
  ) : tableBig && type === "events" ? (
    <div className="big-table-empty-display">
      <img src={AppointmentSvg} className="img-fluid" alt="appointment" />
      <p>There are currently no events to display</p>
    </div>
  ) : tableBig && type === "users" ? (
    <div className="big-table-empty-display">
      <img src={UsersSvg} className="img-fluid" alt="appointment" />
      <p>There are currently no users to display</p>
    </div>
  ) : (
    <p className="simple-empty-table-text">{text}</p>
  );
};

export const DataTable = ({
  type,
  data = [],
  tableBig,
  tableSmall,
  searchTerm,
  filter,
}) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [requesttype, setrequesttype] = useState("");
  const customHistory = useHistory();

  return (
    <div className="data-table">
      {!data.length ? (
        <EmptyText
          type={type}
          tableBig={tableBig}
          tableSmall={tableSmall}
          searchTerm={searchTerm}
        />
      ) : (
        <>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">
                  {type === "users" ? "Full Name" : "Event ID"}
                </th>
                <th scope="col">
                  {type === "users" ? "Email Address" : "Event Title"}
                </th>
                <th scope="col">
                  {type === "users" ? "Phone Number" : "Category"}
                </th>
                <th scope="col">
                  {type === "users" ? "Event Created" : "Event Type"}
                </th>
                <th scope="col">Status</th>
                <th scope="col">Date</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {data.map((x, index) => (
                <tr
                  key={index}
                  onClick={
                    type === "users"
                      ? () => customHistory.push(`/user/${index}/details`)
                      : tableBig
                      ? () => {
                          setSelectedData(x);
                          setOpenDrawer(true);
                        }
                      : null
                  }
                >
                  <th scope="row">
                    {x.fullname || formatNumbering(index + 1)}
                  </th>
                  <td>{x.email || x.title}</td>
                  <td>{x.phone || x.ownerType}</td>
                  <td>{x.event_created || x.type}</td>
                  <td>
                    <span className={`status ${x.status}`}>
                      {x.status || "Unknown"}
                    </span>
                  </td>
                  <td>
                    {x.date ||
                      x.date_joined ||
                      moment.unix(x.createdAt?.seconds).format("DD MMM, YYYY")}
                  </td>

                  <td>
                    <div class="dropdown">
                      <button
                        className="options"
                        type="button"
                        id={`dropdownMenuButton${index}`}
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="fas fa-ellipsis-h"></i>
                      </button>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby={`dropdownMenuButton${index}`}
                      >
                        {type === "users" ? (
                          <Link to={`/user/${index}/details`}>
                            <li>
                              <span class="dropdown-item">View User</span>
                            </li>
                          </Link>
                        ) : (
                          <>
                            <li
                              onClick={() => {
                                setSelectedData(x);
                                setOpenDrawer(true);
                              }}
                            >
                              <span class="dropdown-item">View Event</span>
                            </li>
                            {SHOULD_SHOW_APPROVE_ACTION.includes(filter) ? (
                              <li
                                onClick={() => {
                                  setrequesttype("approve");
                                  setConfirmationModal(true);
                                }}
                              >
                                <span class="dropdown-item">Approve Event</span>
                              </li>
                            ) : null}

                            {SHOULD_SHOW_REJECT_ACTION.includes(filter) ? (
                              <li
                                onClick={() => {
                                  setrequesttype("reject");
                                  setConfirmationModal(true);
                                }}
                              >
                                <span class="dropdown-item">Reject Event</span>
                              </li>
                            ) : null}

                            {SHOULD_SHOW_BLOCK_ACTION.includes(filter) ? (
                              <li
                                onClick={() => {
                                  setrequesttype("block");
                                  setConfirmationModal(true);
                                }}
                              >
                                <span class="dropdown-item">Block Event</span>
                              </li>
                            ) : null}
                          </>
                        )}
                      </ul>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {data.length > 5 ? (
            <div className="pagination">
              <p>Showing 10 of 320</p>
              <div className="pagination-spans">
                <span>{"<"}</span>
                <span>Prev</span>
                <span className="active">1</span>
                <span>2</span>
                <span>3</span>
                <span>Next</span>
                <span>{">"}</span>
              </div>
            </div>
          ) : null}
        </>
      )}
      <SwipeableDrawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor="right"
        className={`details-slider ${selectedData?.video ? "has-video" : ""}`}
      >
        <EventDetails
          event={selectedData}
          closeEvent={() => setOpenDrawer(false)}
          setOpenModal={setConfirmationModal}
          openModal={confirmationModal}
          requesttype={requesttype}
          setrequesttype={setrequesttype}
          filter={filter}
        />
      </SwipeableDrawer>
    </div>
  );
};
