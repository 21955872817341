import React, { useState } from "react";
import { Switch, Route, Link } from "react-router-dom";
import { Dashboard } from "./dashboard";
import { Events } from "./events";
import { Header } from "./header";
import { Users } from "./users";
import { UserDetails } from "./userDetails";
import "./styles/landing-container.scss";

export const LandingContainer = (props) => {
  const [name] = useState(
    JSON.parse(localStorage.getItem("user"))?.fullname || ""
  );
  const _logout = () => {
    localStorage.removeItem("user");
    window.location.href = "/";
  };

  const firstname = name.split(" ")[0];
  const lastname = name.split(" ")[1]?.charAt(0) || "";

  return (
    <div className="landing-container">
      <div className="container-fluid ">
        <div className="row header-row">
          <div className="col-12">
            <Header firstname={firstname} lastname={lastname} name={name} />
          </div>
        </div>
        <div className="row">
          <div className="col-2 menu-side">
            <Link
              to="/"
              className={props?.location?.pathname === "/" ? "active" : ""}
            >
              <i className="fas fa-chart-pie"></i>
              <span>Dashboard</span>
            </Link>
            <Link
              to="/events"
              className={
                props?.location?.pathname === "/events" ? "active" : ""
              }
            >
              <i className="fas fa-bookmark"></i>
              <span>Events</span>
            </Link>
            <Link
              to="/users"
              className={
                props?.location?.pathname === "/users" ||
                props?.location?.pathname.includes("user")
                  ? "active"
                  : ""
              }
            >
              <i className="fas fa-user-friends"></i>
              <span>Users</span>
            </Link>
            <div className="profile-bar">
              <i className="fas fa-user"></i>
              <div className="names">
                <p>
                  {firstname} {lastname ? `${lastname}.` : ""}
                </p>
                <span onClick={_logout}>Logout</span>
              </div>
            </div>
          </div>
          <div className="col-10 app-body">
            <Switch>
              <Route path="/" component={Dashboard} exact />
              <Route path="/users" component={Users} />
              <Route path="/events" component={Events} />{" "}
              <Route path="/user/:id/details" component={UserDetails} />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};
