import React, { useEffect, useState } from "react";
import "./styles/login.scss";
import KurobiSvg from "../assets/svgs/logo.svg";
import CircularProgress from "@mui/material/CircularProgress";
import {
  fauth,
  googleprovider,
  facebookprovider,
  appleprovider,
  twitterprovider,
  db,
} from "../utils/firebase";
import { toast } from "react-toastify";

export const Login = () => {
  const [withEmail, setWithEmail] = useState(false);
  const [emailSteps, setEmailSteps] = useState(0);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (withEmail) {
      console.log("clicked on email");
    }
  }, [withEmail]);

  const loginWithGoogle = async () => {
    try {
      const result = await fauth.signInWithPopup(googleprovider);
      completeLogin(result);
    } catch (err) {
      console.log("login with gogle", err);
    }
  };

  const loginWithFacebook = async () => {
    try {
      const result = await fauth.signInWithPopup(facebookprovider);
      completeLogin(result);
    } catch (error) {
      console.log("login with facebook", error);
    }
  };

  const loginWithApple = async () => {
    try {
      const result = await fauth.signInWithPopup(appleprovider);
      completeLogin(result);
    } catch (error) {
      console.log("login with apple", error);
    }
  };

  const loginWithTwitter = async () => {
    try {
      const result = await fauth.signInWithPopup(twitterprovider);
      completeLogin(result);
    } catch (error) {
      console.log("login with twitter", error);
    }
  };

  const completeLogin = async (result) => {
    setLoading(true);
    try {
      const token = await (await result.user.getIdTokenResult()).token;
      const { user } = result;
      const authBody = {
        authToken: token,
        email: user.email,
        fullname: user.displayName,
        emailVerified: user.emailVerified,
        uid: user.uid,
      };
      const res = await db.collection("users").doc(authBody.uid).get();
      if (!res.exists) {
        toast.error(
          "Oops, we only allow admins into this platform. Please try with an admin email to get in"
        );
        setLoading(false);
      }
      if (res?.data()?.admin) {
        localStorage.setItem("user", JSON.stringify(authBody));
        window.location.href = "/";
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error logging in");
    }
  };

  return (
    <div className="login-page">
      {loading ? (
        <CircularProgress className="login-loader" />
      ) : (
        <img src={KurobiSvg} alt="kurobi" className="img-fluid" />
      )}

      <div className="card login-options">
        {!withEmail ? (
          <>
            <h3>Login to Kurobi Console</h3>
            <div>
              <button
                className="social-button"
                onClick={loginWithGoogle}
                disabled={loading}
              >
                <i className="fa fa-google" aria-hidden="true"></i>
                <span> Continue with Google</span>
              </button>
              <button
                className="social-button"
                onClick={loginWithFacebook}
                disabled={loading}
              >
                <i className="fab fa-facebook"></i>
                <span> Continue with Facebook</span>
              </button>
              <button
                className="social-button"
                onClick={loginWithTwitter}
                disabled={loading}
              >
                <i className="fa fa-twitter" aria-hidden="true"></i>
                <span> Continue with Twitter</span>
              </button>
              <button
                className="social-button"
                onClick={() => {
                  setWithEmail(true);
                  setEmailSteps(1);
                }}
                disabled={loading}
              >
                <i className="far fa-envelope" aria-hidden="true"></i>
                <span> Continue with Email</span>
              </button>
              <button
                className="social-button"
                onClick={loginWithApple}
                disabled={loading}
              >
                <i className="fab fa-apple"></i>{" "}
                <span>Continue with Apple</span>
              </button>
            </div>
          </>
        ) : withEmail && emailSteps === 1 ? (
          <div className="use-email" novalidate>
            <div className="back-to-signin">
              <i className="fas fa-arrow-left"></i>
              <span>Back to sign-in</span>
            </div>
            <h3>Sign in with your Email</h3>
            <p>
              Enter the email associated with your account, and we’ll send a
              magic link to your inbox
            </p>
            <input
              className="form-control"
              placeholder="E-mail"
              required
              value={email}
              name="email"
              id="validationCustom01"
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              onClick={() => {
                if (email) {
                  setEmailSteps(2);
                }
              }}
            >
              Send Magic Link
            </button>
          </div>
        ) : withEmail && emailSteps === 2 ? (
          <div className="use-email">
            <h3>Check your inbox</h3>
            <p>
              Click the link we sent to ‘
              <span className="added-email">{email}</span>’ to complete your
              sign in process
            </p>
            <button>Open Email App</button>
          </div>
        ) : null}
      </div>
    </div>
  );
};
