import React, { useState } from "react";
import "./styles/users.scss";
import { DataTable } from "./table";
import { users } from "../assets/doc";

export const Users = () => {
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <div className="container-fluid">
      <div className="users">
        <div className="filter-options">
          <div className="search-box">
            <i className="fas fa-search"></i>
            <input
              placeholder="Search for events by name,id ..."
              className="form-control"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="filter-box">
            <i className="fas fa-filter"></i>
            <select className="form-control">
              <option selected disabled>
                Select Filter
              </option>
              <option>Active Users</option>
              <option>Banned Users</option>
            </select>
          </div>
          <div className="time-filter-box">
            <i className="far fa-calendar-alt"></i>
            <select className="form-control">
              <option selected disabled>
                All Time
              </option>
              <option>A Month Ago</option>
              <option>Three Months Ago</option>
              <option>Six Months Ago</option>
            </select>
          </div>
        </div>
        <div className="card users-list">
          <DataTable
            data={users.slice(0, 10)}
            type="users"
            tableBig
            searchTerm={searchTerm}
          />
        </div>
      </div>
    </div>
  );
};
