import React from "react";
import ReactDOM from "react-dom";
import AppRouter from "./router";
import "./global.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

ReactDOM.render(
  <React.StrictMode>
    <AppRouter />
    <ToastContainer autoClose={2000} bodyClassName={() => "toast-text"} />
  </React.StrictMode>,
  document.getElementById("root")
);
