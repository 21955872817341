import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "./styles/modal.scss";
import { Button } from "@mui/material";

export const ConfirmationModals = ({
  openModal,
  closeModal,
  requestType,
  eventName,
  closeEvent,
}) => {
  const [event, setEventName] = useState("");

  return (
    <div className="confirmation-modals">
      <Dialog
        open={openModal}
        onClose={closeModal}
        className="confirmation-modal"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="confirmation-modal__dialog_title"
        >
          <div className="confirmation-modal__dialog_title__action-title">
            <p
              className={`confirmation-modal__dialog_title__action-title__icon ${
                requestType === "reject"
                  ? "__reject"
                  : requestType === "block"
                  ? "__block"
                  : ""
              }`}
            >
              <i className="fas fa-calendar-alt" />
            </p>

            <span className="confirmation-modal__dialog_title__action-title__name">
              {requestType === "reject"
                ? "Request Event"
                : requestType === "block"
                ? "Block Event"
                : "Approve Event"}
            </span>
          </div>
          <button
            className="confirmation-modal__dialog_title__close"
            onClick={closeModal}
          >
            <i className="fas fa-times"></i>
          </button>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="confirmation-modal__dialog_content__text"
          >
            {requestType === "reject" ? (
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu et
                id blandit fermentum auctor natoque mi.
              </span>
            ) : requestType === "block" ? (
              <>
                <p className="confirmation-modal__dialog_content__text__block_text_first">
                  This will permanently block the Event{" "}
                  <span className="confirmation-modal__dialog_content__text__block_text_first__event_name">
                    {eventName}
                  </span>
                </p>
                <p className="confirmation-modal__dialog_content__text__block_text_second">
                  To confirm, please enter the name of the team.
                </p>
                <input
                  className="form-control confirmation-modal__dialog_content__text__block_input"
                  placeholder="Enter event name..."
                  onChange={(e) => setEventName(e.target.value)}
                />
              </>
            ) : (
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu et
                id blandit fermentum auctor natoque mi.
              </span>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="confirmation-modal__dialog_actions">
          <Button
            onClick={() => {
              closeModal();
              closeEvent();
            }}
            className={`confirmation-modal__dialog_actions__button_confirm ${
              requestType === "reject"
                ? "__reject"
                : requestType === "block"
                ? "__block"
                : ""
            }`}
            disabled={
              requestType === "block" && event !== eventName ? true : false
            }
          >
            {requestType === "block"
              ? "Block Event"
              : requestType === "reject"
              ? "Confirm Rejection"
              : "Confirm Approval"}
          </Button>
          <Button
            onClick={closeModal}
            className="confirmation-modal__dialog_actions__button_cancel"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
