import React from "react";
import { ConfirmationModals } from "./confirmation-modals";
import "./styles/event-details.scss";
import Picture from "../assets/svgs/pic-1.jpg";
import ReactPlayer from "react-player";
import moment from "moment";
import {
  SHOULD_SHOW_APPROVE_ACTION,
  SHOULD_SHOW_BLOCK_ACTION,
  SHOULD_SHOW_REJECT_ACTION,
} from "../utils/constants";

export const EventDetails = ({
  event,
  closeEvent,
  setrequesttype,
  setOpenModal,
  openModal,
  requesttype,
  filter,
}) => {
  return (
    <div className="event-details">
      <div className="event-details__title-section">
        <div className="event-details__title-section__topic">
          <button
            className="event-details__title-section__topic__back"
            onClick={closeEvent}
          >
            <i className="fas fa-arrow-left"></i>
          </button>
          <p className="event-details__title-section__topic__event-title">
            Recent Event Details
          </p>
        </div>
        <button
          className="event-details__title-section__cancel"
          onClick={closeEvent}
        >
          <i className="fas fa-times"></i>
        </button>
      </div>
      <div className="card event-details__details-card">
        <div className="event-details__details-card__detail">
          <p className="event-details__details-card__detail__title">
            Event Detail
          </p>
          <p className="event-details__details-card__detail__value">
            {event?.title || "Garfield Stories"}
          </p>
        </div>
        <div className="event-details__details-card__detail">
          <p className="event-details__details-card__detail__title">
            Event Author
          </p>
          <p className="event-details__details-card__detail__value">
            {event?.hostName || "Dr Garfield"}
          </p>
        </div>
        {event?.hostImage ? (
          <div className="event-details__details-card__detail">
            <p className="event-details__details-card__detail__title">
              Event Media
            </p>
            <img
              src={event?.hostImageUrl || Picture}
              alt="event-media"
              className="img-fluid event-details__details-card__detail-img"
            />
          </div>
        ) : null}

        {event?.video ? (
          <div className="event-details__details-card__detail">
            <div className="event-details__details-card__detail-video">
              <ReactPlayer
                url={event?.video?.url}
                width={750}
                height={450}
                controls={true}
              />
            </div>
          </div>
        ) : null}

        <div className="event-details__details-card__detail">
          <p className="event-details__details-card__detail__title">Category</p>
          <p className="event-details__details-card__detail__value">
            {event.category}
          </p>
        </div>
        <div className="event-details__details-card__detail">
          <p className="event-details__details-card__detail__title">
            Event Type
          </p>
          <p className="event-details__details-card__detail__value">
            {event.type}
          </p>
        </div>
        <div className="event-details__details-card__detail">
          <p className="event-details__details-card__detail__title">Duration</p>
          <p className="event-details__details-card__detail__value">
            {event?.duration || "10"} Minutes
          </p>
        </div>
        <div className="event-details__details-card__detail">
          <p className="event-details__details-card__detail__title">Status</p>
          <p className="event-details__details-card__detail__value">
            <span className={`status ${event.status}`}>
              {event.status || "unknown"}
            </span>
          </p>
        </div>
        <div className="event-details__details-card__detail">
          <p className="event-details__details-card__detail__title">Date</p>
          <p className="event-details__details-card__detail__value">
            {moment.unix(event?.createdAt?.seconds).format("llll") ||
              "Wednesday October 14, 15:00 - 17:00"}
          </p>
        </div>
        <div className="event-details__details-card__detail">
          <p className="event-details__details-card__detail__title">
            Time Zone
          </p>
          <p className="event-details__details-card__detail__value">
            {event?.timezone || "West Africa Standard Time"}
          </p>
        </div>
        <div className="event-details__details-card__detail">
          <p className="event-details__details-card__detail__title">Notes</p>
          <p className="event-details__details-card__detail__value">
            Our Thermomix team will be showcasing recipes you can use to add to
            your pantry and fridge that will save you time, money and HEALTH!
          </p>
        </div>
      </div>
      <div className="event-details__action">
        <div className="btn-group dropend">
          <button
            type="button"
            className="btn btn-secondary event-details__action__button"
          >
            Approve Event
          </button>
          <button
            type="button"
            className="btn btn-secondary dropdown-toggle dropdown-toggle-split event-details__action__button__small"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          />
          <ul className="dropdown-menu event-details__action__list">
            {SHOULD_SHOW_APPROVE_ACTION.includes(filter) ? (
              <li
                onClick={() => {
                  setOpenModal(true);
                  setrequesttype("approve");
                }}
              >
                <span className="dropdown-item">Approve Event</span>
              </li>
            ) : null}

            {SHOULD_SHOW_REJECT_ACTION.includes(filter) ? (
              <li
                onClick={() => {
                  setOpenModal(true);
                  setrequesttype("reject");
                }}
              >
                <span className="dropdown-item">Reject Event</span>
              </li>
            ) : null}

            {SHOULD_SHOW_BLOCK_ACTION.includes(filter) ? (
              <li
                onClick={() => {
                  setOpenModal(true);
                  setrequesttype("block");
                }}
              >
                <span className="dropdown-item">Block Event</span>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
      <ConfirmationModals
        openModal={openModal}
        closeModal={() => setOpenModal(false)}
        closeEvent={() => closeEvent()}
        requestType={requesttype}
        eventName={event.title}
      />
    </div>
  );
};
