import React, { useEffect, useState } from "react";
import { db } from "../utils/firebase";
import { Shimmer } from "./Shimmer";
import "./styles/events.scss";
import { DataTable } from "./table";

export const Events = () => {
  const [activeTab, setActiveTab] = useState("fe");
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    setLoading(true);
    try {
      async function getEvent() {
        const events = await fetchEvents();
        setEvents(events);
        setLoading(false);
      }
      getEvent();
    } catch (error) {
      console.log("eee", error);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const fetchEvents = async () => {
    try {
      let events;
      if (activeTab === "fe") {
        //this is a query for featured requests
        events = await db
          .collection("call_links")
          .where("status", "!=", "blocked")
          .where("requestfeature", "==", true)
          .where("reviewstatus", "==", "pending")
          .get();
        events = events.docs.map((x) => x.data());
      }
      if (activeTab === "re") {
        //this is a query for reported events
        events = await db
          .collection("call_links")
          .where("reportcount", ">", 0)
          .where("reportstatus", "==", "pending")
          .get();
        events = events.docs.map((x) => x.data());
        events = events.filter((x) => x.status !== "blocked");
      }
      if (activeTab === "be") {
        //this is a query for blocked events
        events = await db
          .collection("call_links")
          .where("status", "==", "blocked")
          .get();
        events = events.docs.map((x) => x.data());
      }

      return events;
    } catch (error) {
      throw error;
    }
  };

  return (
    <div className="container-fluid">
      <div className="events">
        <div className="filter-options">
          <div className="search-box">
            <i className="fas fa-search"></i>
            <input
              placeholder="Search for events by name,id ..."
              className="form-control"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="filter-box">
            <i className="fas fa-filter"></i>
            <select className="form-control">
              <option selected disabled>
                Select Filter
              </option>
              <option>Featured Event</option>
              <option>Promoted Event</option>
            </select>
          </div>
          <div className="time-filter-box">
            <i className="far fa-calendar-alt"></i>
            <select className="form-control">
              <option selected disabled>
                All Time
              </option>
              <option>A Month Ago</option>
              <option>Three Months Ago</option>
              <option>Six Months Ago</option>
            </select>
          </div>
        </div>
        <div className="card events-list">
          <ul className="list">
            <li
              className={activeTab === "fe" ? "active" : ""}
              onClick={() => setActiveTab("fe")}
            >
              Featured Request
              <hr />
            </li>
            <li
              className={activeTab === "re" ? "active" : ""}
              onClick={() => setActiveTab("re")}
            >
              Reported Events
              <hr />
            </li>
            <li
              className={activeTab === "be" ? "active" : ""}
              onClick={() => setActiveTab("be")}
            >
              Blocked Events
              <hr />
            </li>
            <li
              className={activeTab === "pae" ? "active" : ""}
              onClick={() => setActiveTab("pae")}
            >
              Pre-approved Events
              <hr />
            </li>
            <li
              className={activeTab === "ae" ? "active" : ""}
              onClick={() => setActiveTab("ae")}
            >
              Approved Events
              <hr />
            </li>
          </ul>
          <hr />
          <div className="tab-content">
            {loading ? (
              <Shimmer count={10} />
            ) : (
              <DataTable
                type="events"
                data={events}
                tableBig={true}
                searchTerm={searchTerm}
                filter={activeTab}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
