import firebase from "firebase";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

firebase.initializeApp(config);
export const db = firebase.firestore();

export const googleprovider = new firebase.auth.GoogleAuthProvider();

export const facebookprovider = new firebase.auth.FacebookAuthProvider();

export const appleprovider = new firebase.auth.OAuthProvider("apple.com");

export const twitterprovider = new firebase.auth.TwitterAuthProvider();

export const fauth = firebase.auth();
