const users = [
  {
    fullname: "Martin Gedit",
    email: "martingeidt@dipsea.com",
    phone: "+2348069024420",
    event_created: 13,
    status: "active",
    date_joined: "Oct. 14, 2021",
  },
  {
    fullname: "Wilson Westervelt",
    email: "wilson_westervelt@gmail.com",
    phone: "+2348132780945",
    event_created: 8,
    status: "banned",
    date_joined: "Oct. 13, 2021",
  },
  {
    fullname: "Tiana Bergson",
    email: "tiana_berg92@outlook.com",
    phone: "+234089021280",
    event_created: 2,
    status: "banned",
    date_joined: "Oct. 10, 2021",
  },
  {
    fullname: "Emerson Lubin",
    email: "emerson12@outlodjsjdjsjdjdjsjdjdjsok.com",
    phone: "+2347089021280",
    event_created: 10,
    status: "active",
    date_joined: "Oct. 14, 2021",
  },
  {
    fullname: "Kadin Gedit",
    email: "martingeidt@dipsea.com",
    phone: "+2348069024420",
    event_created: 13,
    status: "banned",
    date_joined: "Oct. 14, 2021",
  },
  {
    fullname: "Martin Gedit",
    email: "martingeidt@dipsea.com",
    phone: "+2348069024420",
    event_created: 13,
    status: "active",
    date_joined: "Oct. 14, 2021",
  },
  {
    fullname: "Wilson Westervelt",
    email: "wilson_westervelt@gmail.com",
    phone: "+2348132780945",
    event_created: 8,
    status: "banned",
    date_joined: "Oct. 13, 2021",
  },
  {
    fullname: "Tiana Bergson",
    email: "tiana_berg92@outlook.com",
    phone: "+234089021280",
    event_created: 2,
    status: "banned",
    date_joined: "Oct. 10, 2021",
  },
  {
    fullname: "Tiana Bergson",
    email: "tiana_berg92@outlook.com",
    phone: "+234089021280",
    event_created: 2,
    status: "banned",
    date_joined: "Oct. 10, 2021",
  },
  {
    fullname: "Emerson Lubin",
    email: "emerson12@outlodjsjdjsjdjdjsjdjdjsok.com",
    phone: "+2347089021280",
    event_created: 10,
    status: "active",
    date_joined: "Oct. 14, 2021",
  },
];

const events = [
  {
    id: "#0001",
    title: "Everyone is cooking",
    category: "One-to-One",
    type: "Featured",
    status: "pre-approved",
    date: "Oct. 14, 2021",
  },
  {
    id: "#0002",
    title: "Everyone is dancing",
    category: "One-to-One",
    type: "Promoted",
    status: "approved",
    date: "Oct. 14, 2021",
    hasMedia: true,
  },
  {
    id: "#0003",
    title: "Everyone is videoing",
    category: "One-to-One",
    type: "Promoted",
    status: "blocked",
    date: "Oct. 14, 2021",
    // videourl: "https://www.youtube.com/watch?v=Gc1oaJqMn3M",
    videourl:
      "https://firebasestorage.googleapis.com/v0/b/kurobidev-182a5-6zlma-dev-videos/o/call_links%2F-MYO9DERB-C_MRVYFzDV%2Fvideo.mp4?alt=media&token=8900afa4-306b-428d-a4ee-5ab25cfbf29f",
  },
  {
    id: "#0004",
    title: "Everyone is farming",
    category: "One-to-One",
    type: "Featured",
    status: "reported",
    date: "Oct. 14, 2021",
  },
  {
    id: "#0005",
    title: "Everyone is cooking",
    category: "Group-Broadcast",
    type: "Featured",
    status: "approved",
    date: "Oct. 14, 2021",
  },
  {
    id: "#0005",
    title: "Everyone is farming",
    category: "One-to-One",
    type: "Featured",
    status: "reported",
    date: "Oct. 14, 2021",
  },
  {
    id: "#0006",
    title: "Everyone is fishing",
    category: "One-to-One",
    type: "Promoted",
    status: "blocked",
    date: "Oct. 14, 2021",
  },
  {
    id: "#0007",
    title: "Everyone is dancing",
    category: "One-to-One",
    type: "Promoted",
    status: "approved",
    date: "Oct. 14, 2021",
  },
  {
    id: "#0008",
    title: "Everyone is smiling",
    category: "One-to-One",
    type: "Deatured",
    status: "pre-approved",
    date: "Oct. 14, 2021",
  },
  {
    id: "#0009",
    title: "Everyone is cooking",
    category: "One-to-One",
    type: "Featured",
    status: "blocked",
    date: "Oct. 14, 2021",
  },
  {
    id: "#0010",
    title: "Everyone is cooking",
    category: "One-to-One",
    type: "Featured",
    status: "reported",
    date: "Oct. 14, 2021",
  },
  {
    id: "#0011",
    title: "Everyone is fishing",
    category: "One-to-One",
    type: "Featured",
    status: "blocked",
    date: "Oct. 14, 2021",
  },
];

export { events, users };
