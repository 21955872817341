import React from "react";
import KurobiSvg from "../assets/svgs/logo.svg";
import "./styles/header.scss";

export const Header = (props) => {
  return (
    <div className="header">
      <div className="row justify-content-between align-items-baseline">
        <div className="col-6">
          <img src={KurobiSvg} alt="kurobi-logo" className="img-fluid" />{" "}
          <span>Kurobi</span>
        </div>
        <div className="col-6 row justify-content-end menu-options align-items-baseline">
          <p>
            <i className="fas fa-bell"></i>
          </p>
          <p className="user-p">
            <i className="fas fa-user"></i>
          </p>
          <p>
            <span className="user-name">
              {props.firstname || ""}{" "}
              {props.lastname ? `${props.lastname}.` : ""}{" "}
              <i className="fas fa-angle-down"></i>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
