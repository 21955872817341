import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { users } from "../assets/doc";
import "./styles/dashboard.scss";
import { DataTable } from "./table";
import { db } from "../utils/firebase";
import { Shimmer } from "./Shimmer";

export const Dashboard = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function eventGetter() {
      const events = await getEvents();
      setEvents(events);
      setLoading(false);
    }
    eventGetter();
  }, []);

  const getEvents = async () => {
    let events = await db
      .collection("call_links")
      .where("enabled", "==", true)
      .where("published", "==", true)
      .limit(5)
      .get();
    events = events.docs.map((x) => x.data());
    return events;
  };

  return (
    <div className="container-fluid">
      <div className="dashboard">
        <div className="dashboard__overview card">
          <div className="dashboard__overview-text__select">
            <h4>Overview</h4>
            <div className="dashboard__overview-text__select-select_div">
              <i class="far fa-calendar-alt"></i>
              <select className="form-select">
                <option>Last 30 days</option>
              </select>
            </div>
          </div>
          <div className="dashboard__overview-cards__data">
            <div className="dashboard__overview-cards__data__total events">
              <h3>Total Events</h3>
              <p className="total-no">1,029</p>
              <p className="stats increase">
                <i className="fas fa-chevron-circle-up"></i>
                <span>2.9% </span> since last week
              </p>
            </div>
            <div className="dashboard__overview-cards__data__total users">
              <h3>Total Events</h3>
              <p className="total-no">1,029</p>
              <p className="stats decrease">
                <i className="fas fa-chevron-circle-down"></i>
                <span>3.1% </span> since last week
              </p>
            </div>
            <div className="dashboard__overview-cards__data__total reported">
              <h3>Total Events</h3>
              <p className="total-no">1,029</p>
              <p className="stats increase">
                <i className="fas fa-chevron-circle-up"></i>
                <span>4.2% </span> since last week
              </p>
            </div>
          </div>
        </div>
        <div className="card dashboard__recent-events">
          <div className="dashboard__recent-events__text-select">
            <h4>Recent Events</h4>
            <Link
              className="dashboard__recent-events__text-select-view-all"
              to={"/events"}
            >
              View All<i className="fas fa-chevron-right"></i>
            </Link>
          </div>
          {loading ? (
            <Shimmer count={5} />
          ) : (
            <DataTable type="events" data={events.slice(0, 5)} />
          )}
        </div>

        <div className="card dashboard__recent-events">
          <div className="dashboard__recent-events__text-select">
            <h4>Recent Users</h4>
            <Link
              className="dashboard__recent-events__text-select-view-all"
              to={"/users"}
            >
              View All<i className="fas fa-chevron-right"></i>
            </Link>
          </div>
          {loading ? (
            <Shimmer count={5} />
          ) : (
            <DataTable type="users" data={users.slice(5)} tableSmall />
          )}
        </div>
      </div>
    </div>
  );
};
