import React, { useState } from "react";
import { events, users } from "../assets/doc";
import "./styles/userDetails.scss";
import { DataTable } from "./table";
import { useHistory } from "react-router";

export const UserDetails = (props) => {
  const [id] = useState(Number(props?.match?.params?.id));
  const user = users[id];
  const customHistory = useHistory();

  return (
    <div className="user-details">
      <button
        className="user-details__back-button"
        onClick={() => customHistory.goBack()}
      >
        <i class="far fa-arrow-alt-circle-left"></i>
        <span>Back to Users </span>
      </button>
      <div className="card user-details__details">
        <h3>User Information</h3>
        <hr />
        <div className="user-details__details-stats">
          <div className="row">
            <div className="col-3">
              <p className="user-details__details-stats__title">Full Name</p>
              <p className="user-details__details-stats__value">
                {user.fullname}
              </p>
            </div>
            <div className="col-3">
              <p className="user-details__details-stats__title">
                Email Address
              </p>
              <p className="user-details__details-stats__value">{user.email}</p>
            </div>
            <div className="col-3">
              <p className="user-details__details-stats__title">Phone Number</p>
              <p className="user-details__details-stats__value">{user.phone}</p>
            </div>
            <div className="col-3">
              <p className="user-details__details-stats__title">
                Events Created
              </p>
              <p className="user-details__details-stats__value">21</p>
            </div>
            <div className="col-3">
              <p className="user-details__details-stats__title">Status</p>
              <p className="user-details__details-stats__value">
                <span className={`status ${user.status}`}>{user.status}</span>
              </p>
            </div>
            <div className="col-3">
              <p className="user-details__details-stats__title">Date Joined</p>
              <p className="user-details__details-stats__value">
                {user.date_joined}
              </p>
            </div>
          </div>
        </div>
        <h3>Events</h3>
        <hr />
        <DataTable data={events.slice(0, 5)} type="events" tableBig />
      </div>
    </div>
  );
};
