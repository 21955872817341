export const formatNumbering = (num) => {
  if (String(num).length === 1) {
    return `00${num}`;
  }
  if (String(num).length === 2) {
    return `0${num}`;
  }
  return num;
};

export const SHOULD_SHOW_BLOCK_ACTION = ["re"];
export const SHOULD_SHOW_APPROVE_ACTION = ["fe"];
export const SHOULD_SHOW_REJECT_ACTION = ["fe"];
